import * as React from "react"
import * as styles from "./css/index.module.styl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PersonPictureIcon from "../images/home/home-person.png"
import PersonPictureIconRtl from "../images/home/home-person-rtl.png"
import locationIcon from "../images/home/icon_location.svg"
// import BarwaqtStatement from '../images/home/barwaqt-statement.png'
import telIcon from "../images/home/icon_tel.svg"
import mailIcon from "../images/home/icon_mail.svg"
import warningIcon from "../images/home/icon-dialog-warning.svg"
import { widthInfo } from "../util/index.js"
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next"

let width = widthInfo()
const flag = Boolean(width > 1024)
const IndexPage = () => {
  const { t } = useTranslation()
  const { i18n } = useI18next()
  const [on, setOn] = React.useState(false)
  const curLang = i18n.language
  const [lang, setLang] = React.useState(curLang)
  const [isRtl, setRtl] = React.useState(false)
  const [isShowDialog, setShowDialog] = React.useState(false)

  React.useEffect(() => {
    width = widthInfo()
    if (lang === "en") {
      setRtl(false)
    } else {
      setRtl(true)
    }
    const isDialogClosed = sessionStorage.getItem("dialogClosed")
    if (!isDialogClosed) {
      setShowDialog(true)
    }
  }, [])

  const handleCloseDialog = () => {
    // sessionStorage.setItem("dialogClosed", "true")
    setShowDialog(false)
  }

  const lightSwitch = () => setOn(on => !on)
  const htmlContent = t("announcement.desc.para5-1")
  return (
    <Layout>
      <Seo title="Home" />
      <section className={styles.homeBanner}>
        <div
          className={`${styles.homeHeaderLeft} ${styles.homeBannerLeft}`}
        ></div>
        <div
          className={`${styles.homeHeaderRight} ${styles.homeBannerRight}`}
        ></div>
        {flag ? (
          <div className={`${styles.homeBannerInner}`}>
            <div className={styles.homeBannerItem}>
              <div>
                <div
                  className={`${styles.homeBannerPara} ${styles.homeBannerText}`}
                >
                  <h2>{t("banner.title")}</h2>
                  <p>{t("banner.subtitle")}</p>
                </div>
                <ul className={styles.homeBannerList}>
                  <li className={styles.homeBannerItem}>
                    <StaticImage
                      className={styles.homeBannerItemIcon}
                      src="../images/home/task_alt_black_24dp@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeBannerItemText}>
                      {t("banner.desc.item1")}
                    </span>
                  </li>
                  <li className={styles.homeBannerItem}>
                    <StaticImage
                      className={styles.homeBannerItemIcon}
                      src="../images/home/task_alt_black_24dp@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeBannerItemText}>
                      {t("banner.desc.item2")}
                    </span>
                  </li>
                  <li className={styles.homeBannerItem}>
                    <StaticImage
                      className={styles.homeBannerItemIcon}
                      src="../images/home/task_alt_black_24dp@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeBannerItemText}>
                      {t("banner.desc.item3")}
                    </span>
                  </li>
                </ul>
                <section className={styles.downloadEntries}>
                  {/* <a
                    aria-label="index download"
                    className={styles.homeBannerButton}
                    href="https://play.google.com/store/apps/details?id=com.loan.cash.credit.barwaqt.tez.paisa.jazz.financial.ready.easy"
                  ></a> */}
                  {/* Pakistan_2.0.0.3048_googleplay_104488.apk */}
                  <a
                    aria-label="index download"
                    className={styles.homeBannerButton}
                    href="https://www.barwaqtfintec.com/Pakistan_2.0.0.3048_googleplay_104488.apk"
                    download
                  ></a>
                  {/* <a className={styles.downloadAppEntry} href="https://api.barwaqtfintec.com/api/v1/web/download_app">
                    <StaticImage
                      className={styles.downloadAppIcon}
                      src="../images/home/icon_download.svg"
                      alt="A corgi smiling happily"
                    />
                    <span>{t('banner.download')}</span>
                  </a> */}
                </section>
              </div>
            </div>
            <div className={styles.PersonhomeBanner}>
              {isRtl ? (
                <img
                  alt="A corgi smiling happily"
                  className={styles.homeBannerPerson}
                  src={PersonPictureIconRtl}
                />
              ) : (
                <img
                  alt="A corgi smiling happily"
                  className={styles.homeBannerPerson}
                  src={PersonPictureIcon}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.homeBannerInner}>
            <div className={styles.h5HomeBannerInner}>
              {/* <StaticImage className={styles.H5TextIcon}
                src="../images/home/h5text.png"
                alt="A corgi smiling happily"
              /> */}
              <div
                className={`${styles.homeBannerPara} ${styles.homeBannerText}`}
              >
                <h2>{t("banner.title")}</h2>
                <p>{t("banner.subtitle")}</p>
              </div>
              <div className={styles.H5IndexContent}>
                <div className={styles.H5IndexContentLeft}>
                  <ul className={styles.homeBannerList}>
                    <li className={styles.homeBannerItem}>
                      <StaticImage
                        className={styles.homeBannerItemIcon}
                        alt="A corgi smiling happily"
                        src="../images/home/task_alt_black_24dp@2x.png"
                        placeholder="blurred"
                      />
                      <span className={styles.homeBannerItemText}>
                        {t("banner.desc.item1")}
                      </span>
                    </li>
                    <li className={styles.homeBannerItem}>
                      <StaticImage
                        className={styles.homeBannerItemIcon}
                        src="../images/home/task_alt_black_24dp@2x.png"
                        alt="A corgi smiling happily"
                        placeholder="blurred"
                      />
                      <span className={styles.homeBannerItemText}>
                        {t("banner.desc.item2")}
                      </span>
                    </li>
                    <li className={styles.homeBannerItem}>
                      <StaticImage
                        className={styles.homeBannerItemIcon}
                        src="../images/home/task_alt_black_24dp@2x.png"
                        alt="A corgi smiling happily"
                        placeholder="blurred"
                      />
                      <span className={styles.homeBannerItemText}>
                        {t("banner.desc.item3")}
                      </span>
                    </li>
                  </ul>
                  <a
                    aria-label="index download"
                    className={styles.homeBannerButton}
                    href="https://www.barwaqtfintec.com/Pakistan_2.0.0.3048_googleplay_104488.apk"
                    download
                  ></a>
                  {/* <a className={styles.downloadAppEntry} href="https://api.barwaqtfintec.com/api/v1/web/download_app">
                    <StaticImage
                      className={styles.downloadAppIcon}
                      src="../images/home/icon_download.svg"
                      alt="A corgi smiling happily"
                    />
                    <span>{t('banner.download')}</span>
                  </a> */}
                  <a
                    aria-label="index download"
                    className={styles.mobileSeedcredLink}
                    target="_blank"
                    href="https://www.seedcredfintec.com/"
                  ></a>
                </div>
                <div className={styles.H5IndexContentRight}>
                  {isRtl ? (
                    <StaticImage
                      className={`${styles.homeBannerPerson}`}
                      src="../images/home/H5home-person-rtl@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                  ) : (
                    <StaticImage
                      className={`${styles.homeBannerPerson}`}
                      src="../images/home/H5home-person@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <div className={styles.homeInfo}>
        <div>
          <div className={styles.homeInfoStatementwrapper}>
            {/* <h3 className={`${styles.homeInfoDetailTitle} ${styles.homeInfoStatementTitle}`}>{t('notice.title')}</h3>
            <div className={styles.barwaqtStatementWrapper}>
              <img src={BarwaqtStatement} className={styles.barwaqtStatement} />
            </div> */}
            <div className={`${styles.homeInfoStatement}`}>
              <h3
                className={`${styles.homeInfoDetailTitle} ${styles.homeInfoStatementTitle}`}
              >
                {t("announcement.title")}
              </h3>
              <main className={styles.homeInfoStatementList}>
                <p>
                  {t("announcement.desc.para1")}​<br />
                  {t("announcement.desc.para2")}
                  <br />
                  {t("announcement.desc.para3")}
                </p>
                <p>
                  {t("announcement.desc.para4")}​​
                  <br />
                  {t("announcement.desc.para5")}​​
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  ></span>
                  <br />
                  {t("announcement.desc.para6")}​​
                  <br />
                  {t("announcement.desc.para7")}​​
                  <br />
                  {t("announcement.desc.para8")}​​
                  <br />
                </p>
                <p>{t("announcement.desc.para9")}</p>
              </main>
            </div>
          </div>
          <div className={`${styles.providerContainer}`}>
            <h3 className={styles.homeInfoDetailTitle}>
              {t("provide.title")}​​
            </h3>
            <section className={styles.providerList}>
              <main className={styles.homeInfoDetailList}>
                <ul>
                  <li className={styles.homeInfoDetailItem}>
                    <StaticImage
                      className={styles.homeInfoDetailIcon}
                      src="../images/home/info-icon1@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeInfoDetailText}>
                      {t("provide.desc.item1")}
                    </span>
                  </li>
                  <li className={styles.homeInfoDetailItem}>
                    <StaticImage
                      className={styles.homeInfoDetailIcon}
                      src="../images/home/info-icon2@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeInfoDetailText}>
                      {t("provide.desc.item2")}
                    </span>
                  </li>
                  <li className={styles.homeInfoDetailItem}>
                    <StaticImage
                      className={styles.homeInfoDetailIcon}
                      src="../images/home/info-icon3@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeInfoDetailText}>
                      {t("provide.desc.item3")}
                    </span>
                  </li>
                  <li className={styles.homeInfoDetailItem}>
                    <StaticImage
                      className={styles.homeInfoDetailIcon}
                      src="../images/home/info-icon4@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeInfoDetailText}>
                      {t("provide.desc.item4")}
                    </span>
                  </li>
                  <li className={styles.homeInfoDetailItem}>
                    <StaticImage
                      className={styles.homeInfoDetailIcon}
                      src="../images/home/info-icon5@2x.png"
                      alt="A corgi smiling happily"
                      placeholder="blurred"
                    />
                    <span className={styles.homeInfoDetailText}>
                      {t("provide.desc.item5")}
                    </span>
                  </li>
                </ul>
              </main>
              <aside>
                <StaticImage
                  className={styles.homeInfoDetailBan}
                  src="../images/home/info-ban.png"
                  alt="A corgi smiling happily"
                  placeholder="blurred"
                />
                <a
                  aria-label="index download"
                  className={styles.homeInfoDetailButton}
                  href="https://www.barwaqtfintec.com/Pakistan_2.0.0.3048_googleplay_104488.apk"
                  download
                >
                  {" "}
                </a>
              </aside>
            </section>
          </div>
        </div>
        {width > 1024 ? (
          <div className={styles.homeInfoProgress}>
            <div className={styles.homeInfoProgressInner}>
              <h3 className={styles.homeInfoProgressTitle}>
                {t("borrow.title")}
              </h3>
              <ul className={styles.homeInfoProgressList}>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon1@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon2@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon3@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon4@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    alt="A corgi smiling happily"
                    src="../images/home/progress-icon5@2x.png"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    alt="A corgi smiling happily"
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon6@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
              </ul>
              <ul className={styles.homeInfoProgressList}>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  <span>{t("borrow.process.step1")}</span>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  <span>{t("borrow.process.step2")}</span>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  <span>{t("borrow.process.step3")}</span>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  <span>{t("borrow.process.step4")}</span>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  <span>{t("borrow.process.step5")}</span>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  <span>{t("borrow.process.step6")}</span>
                </li>
              </ul>
              <GatsbyLink to="/ApplicationProcess">
                {" "}
                <div
                  className={`${styles.homePaymentBtn} ${styles.homePaymentBtnMargin}`}
                >
                  {t("borrow.btnName")}
                </div>
              </GatsbyLink>
            </div>
          </div>
        ) : (
          <div className={styles.homeInfoProgress}>
            <div className={styles.homeInfoProgressInner}>
              <h3 className={styles.homeInfoProgressTitle}>
                {t("borrow.title")}
              </h3>
              <ul
                className={`${styles.homeInfoProgressList} ${styles.homeInfoProgressListImg}`}
              >
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon1@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon2@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon3@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
              </ul>
              <ul className={styles.homeInfoProgressList}>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  {t("borrow.process.step1")}
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <p className={styles.homeInfoProgressItemArrow}></p>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  {t("borrow.process.step2")}
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <p className={styles.homeInfoProgressItemArrow}></p>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  {t("borrow.process.step3")}
                </li>
              </ul>
              <ul
                className={`${styles.homeInfoProgressList} ${styles.homeInfoProgressListImg}`}
              >
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    alt="A corgi smiling happily"
                    src="../images/home/progress-icon4@2x.png"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon5@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <StaticImage
                    className={styles.homeInfoProgressItemArrow}
                    src="../images/home/ic_expand_more_black_24px@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemPicture}`}
                >
                  <StaticImage
                    className={styles.homeInfoProgressItemIcon}
                    src="../images/home/progress-icon6@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </li>
              </ul>

              <ul className={styles.homeInfoProgressList}>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  {t("borrow.process.step4")}
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <p className={styles.homeInfoProgressItemArrow}></p>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  {t("borrow.process.step5")}
                </li>
                <li className={styles.homeInfoProgressItem}>
                  <p className={styles.homeInfoProgressItemArrow}></p>
                </li>
                <li
                  className={`${styles.homeInfoProgressItem} ${styles.homeInfoProgressItemText}`}
                >
                  {t("borrow.process.step6")}
                </li>
              </ul>
              <GatsbyLink to="/ApplicationProcess">
                {" "}
                <div
                  className={`${styles.homePaymentBtn} ${styles.homePaymentBtnMargin}`}
                >
                  {t("borrow.btnName")}
                </div>
              </GatsbyLink>
              {/* <Link to='/ApplicationProcess'>
                <StaticImage
                  className={`${styles.homePaymentBtn} ${styles.homePaymentBtnMargin}`}
                  src="../images/home/showMOoreBtn.png"
                  alt="A corgi smiling happily"
                />
              </Link> */}
            </div>
          </div>
        )}
        <div className={styles.homePayment}>
          <div className={styles.homePaymentRight}>
            <div className={styles.homePaymentTitle}>{t("payback.title")}</div>
            {width > 750 ? (
              <GatsbyLink to="/repaymentViabyWa">
                {" "}
                <div className={styles.homePaymentBtn}>
                  {t("payback.btnName")}
                </div>
              </GatsbyLink>
            ) : null}
          </div>
          <StaticImage
            className={styles.homePaymentIcon}
            src="../images/home/payment@2x.png"
            alt="A corgi smiling happily"
            placeholder="blurred"
          />
          {width < 750 ? (
            <GatsbyLink to="/repaymentViabyWa">
              {" "}
              <div className={styles.homePaymentBtn}>
                {t("payback.btnName")}
              </div>
            </GatsbyLink>
          ) : null}
        </div>
        <section className={styles.contactInformation}>
          <div className={styles.lf}>
            <StaticImage
              className={styles.contactInformationBanner}
              src="../images/home/contactUs@2x.png"
              alt="How to Contact Us"
              placeholder="blurred"
            />
          </div>
          <div className={styles.rt}>
            <div>
              <h1>{t("contact.title")}</h1>
              <ul>
                <li>
                  <img src={locationIcon} alt="Location" />
                  <span>{t("contact.info.item1")}</span>
                </li>
                <li>
                  <img src={mailIcon} alt="Mail" />
                  <span>{t("contact.info.item2")}</span>
                </li>
                <li>
                  <img src={telIcon} alt="Tel" />
                  <span>{t("contact.info.item3")}</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div
        className={`${styles.menu} ${on ? "block" : "none"}`}
        onClick={() => lightSwitch()}
      >
        <ul className={styles.menuList}>
          <div className={styles.trangle}></div>
          <span className={styles.menuListItem}> Home </span>
          <span className={styles.menuListItem}>
            <Link to="/aboutUs">About Barwaqt</Link>{" "}
          </span>
          <span className={styles.menuListItem}>
            <Link to="/faq">FAQs</Link>
          </span>
          <span className={styles.menuListItem}>
            <Link to="/complaints">Complaints</Link>
          </span>
        </ul>
      </div>
      <section
        className={`${styles.overlay} ${styles.fade} ${
          isShowDialog ? styles.show : ""
        }`}
      >
        <div className={`${styles.dialog}`}>
          <div className={styles.dialogContent}>
            <div className={styles.dialogIcon}>
              <img src={warningIcon} />
            </div>
            <h2>Announcement for Barwaqt App Users.</h2>
            <div className={styles.dialogDesc}>
              <p>
                Dear Valued Customer,
                <br />
                We would like to inform you of the following important steps to
                ensure the smooth processing of your repayments:
              </p>
              <p>
                1. You can settle your loan by depositing or crediting the
                repayment amount to the following account:
                [PK12MUCB1326127351002916] Once the repayment is made, please
                take a screenshot of the payment confirmation.
              </p>
              <p>
                2.Send the screenshot to our email address:
                [cs@barwaqtfintec.com].
              </p>
              <p>
                3.Kindly allow 7 working days for us to process your request. We
                will respond to your email with an update on the status of your
                order. If your order is fully settled, we will also attach a
                settlement certificate.
              </p>
              <p>
                4. Please note that for orders settled between the 11th of last
                month and the 10th of this month, your credit status will be
                updated between the 10th and 20th of this month.
              </p>
              <p>
                Thank you for your cooperation, and please feel free to reach
                out if you have any further questions. Best regards, <br />{" "}
                Barwaqt App Team
              </p>
            </div>
            <div className={styles.dialogBtn}>
              <button onClick={() => handleCloseDialog()}>OK</button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "header"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
